import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContextProvider';

export const Page = ({ children, id }: { children: JSX.Element; id: string }) => {
    const context = useContext(GlobalContext);
    return (
        <div
            id={id}
            className="mceNonEditable page"
            style={{
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                minHeight: '1122px',
                padding: '96px 96px',
                width: '793.7px',
                marginBottom: '16px',
                display: 'flex',
                flexDirection: 'column',
                outline: 'none',
                lineHeight: '200%',
                fontSize: '12pt',
                position: 'relative',
            }}
        >
            <div
                className="header"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    width: '793.7px',
                    position: 'absolute',
                    marginLeft: '96px',
                    marginRight: '96px',
                    marginTop: '48px',
                }}
            >
                <div style={{ display: 'flex', flexGrow: 1 }}>PAGE TITLE {context.titlePage.title}</div>
                <div>PAGE #</div>
            </div>
            {children}
        </div>
    );
};

export default Page;
