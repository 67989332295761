/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from 'react';

import { navigate } from 'gatsby';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, getCookie, getCookieOptions, handleError, handleSuccess } from '../utility';

export const useAuth = () => {
    const context = useContext(GlobalContext);
    const [authLoading, setAuthLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['authToken', 'user', 'refreshToken']);

    const goToLoginPage = () => {
        if (process.env.ENV === 'production') {
            window.location.href = 'https://auth.etutordev.com?url=https://autoapa.etutordev.com';
        } else {
            navigate('/login');
        }
    };

    const adminCheck = () => {
        const user = store.get('user');
        if (!user?.roles?.includes('admin')) {
            navigate('/');
        }
    };

    const isAdmin = store.get('user')?.roles?.includes('admin');

    const localLoginCheck = async () => {
        const localToken = getCookie('authToken');
        let user = store.get('user');
        const isLoginPage = window.location.pathname.includes('login');
        if (localToken && !user) {
            const decodedToken = jwt_decode(localToken);
            //@ts-ignore
            const localUserResponse = await apiRequester.getLocalUser({ userId: String(decodedToken?.sub) });
            const { _id, roles } = localUserResponse.user;
            const finalUser = {
                _id,
                emailId: undefined,
                email: undefined,
                roles,
            };
            store.set('user', finalUser);
            user = finalUser;
            context.setLoggedInUser(finalUser);
        }
        if (!localToken || !user) {
            !localToken && console.log('Token not found.');
            !user && console.log('Current user details not found.');

            removeCookie('authToken');
            removeCookie('refreshToken');
            store.remove('user');

            !isLoginPage && goToLoginPage();
        } else {
            const decodedToken: { exp: number } = jwt_decode(localToken);
            const currentDate = new Date();
            context.setLoggedInUser(user);

            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                if (cookies['refreshToken']) {
                    async function generateNewAccessToken() {
                        const newAccessToken = await apiRequester.getNewAccessToken(cookies['refreshToken']);
                        const { access_token: token, refresh_token: refreshToken } = newAccessToken;
                        setCookie('authToken', token, getCookieOptions());
                        setCookie('refreshToken', refreshToken, getCookieOptions());
                    }
                    generateNewAccessToken();
                } else {
                    console.log('Token expired.');
                    removeCookie('authToken');
                    store.remove('user');
                    removeCookie('refreshToken');
                    !isLoginPage && goToLoginPage();
                }
            } else {
                return true;
            }
        }
    };

    const login = async (emailId: string, password: string) => {
        try {
            setAuthLoading(true);
            const myHeaders = new Headers();
            myHeaders.append('Accept', 'application/json');
            myHeaders.append('Content-Type', 'application/json');
            const loginResponse = await fetch('https://auth.etutordev.com/api/login', {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    email: emailId,
                    password,
                }),
                redirect: 'follow',
            });
            const response = (await loginResponse.json()) as any;
            const { access_token: token, email, refresh_token: refreshToken } = response;
            setCookie('authToken', token, getCookieOptions());
            setCookie('refreshToken', refreshToken, getCookieOptions());
            const decodedToken = jwt_decode(token);
            //@ts-ignore
            const localUserResponse = await apiRequester.getLocalUser({ userId: String(decodedToken?.sub) });
            const { _id, roles } = localUserResponse.user;
            const user = {
                _id,
                emailId: email,
                email,
                roles,
            };
            console.log('User is', user);
            store.set('user', user);
            context.setLoggedInUser(user);
            if (roles?.includes('admin')) navigate('/admin/users');
            else navigate('/');
        } catch (err) {
            console.log('Error is', err);
            // handleError(err);
        } finally {
            setAuthLoading(false);
        }
    };

    const forgotPassword = async (emailId: string) => {
        try {
            setAuthLoading(true);
            await apiRequester.forgotPassword({ emailId });
            handleSuccess('Password reset link sent to your email.');
        } catch (err) {
            handleError(err);
        } finally {
            setAuthLoading(false);
        }
    };

    const resetPassword = async (newPassword: string, token: string | null) => {
        try {
            setAuthLoading(true);
            await apiRequester.resetPassword({ newPassword, token });
            handleSuccess('Password reset successfully. Sign In to continue');
            removeCookie('authToken');
            store.remove('user');
            removeCookie('refreshToken');
            goToLoginPage();
            return true;
        } catch (err) {
            handleError(err);
            return false;
        } finally {
            setAuthLoading(false);
        }
    };

    const logout = () => {
        removeCookie('authToken');
        store.remove('user');
        removeCookie('refreshToken');
        goToLoginPage();
        context.setLoggedInUser(undefined);
    };

    return {
        authLoading,
        login,
        forgotPassword,
        resetPassword,
        localLoginCheck,
        logout,
        loggedInUser: context.loggedInUser,
        adminCheck,
        isAdmin,
    };
};

export default useAuth;
