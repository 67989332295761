import { useState } from 'react';

import { navigate } from 'gatsby';
import store from 'store';

import {} from '../hooks/';
import { apiRequester, handleError, handleSuccess } from '../utility';

export const userUser = () => {
    const [userLoading, setUserLoading] = useState(false);
    const [users, setUsers] = useState<Users.User[]>([]);

    const refreshUsers = async () => {
        try {
            setUserLoading(true);
            const users = await apiRequester.listUsers();
            setUsers(users);
        } catch (err) {
            handleError(err);
        } finally {
            setUserLoading(false);
        }
    };

    const createUser = async (user: Users.User) => {
        try {
            setUserLoading(true);
            await apiRequester.createUser(user);
            handleSuccess('User has been created');
            await refreshUsers();
        } catch (err) {
            handleError(err);
        } finally {
            setUserLoading(false);
        }
    };

    const deleteUser = async (userId: string) => {
        try {
            setUserLoading(true);
            await apiRequester.deleteUser(userId);
            await refreshUsers();
        } catch (err) {
            handleError(err);
        } finally {
            setUserLoading(false);
        }
    };

    return { userLoading, createUser, refreshUsers, users, deleteUser };
};

export default userUser;
