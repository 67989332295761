const formatArray = (arr: any[] = []) => {
    let outStr = '';
    if (arr.length === 1) {
        outStr = arr[0];
    } else if (arr.length === 2) {
        outStr = arr.join(' & ');
    } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ', & ' + arr.slice(-1);
    }
    return outStr;
};

const generateReferenceStringForBook = (reference: AutoAPA.Reference) => {
    const {
        bookType,
        edition,
        urlDoi,
        publishers,
        format,
        publicationYear,
        bookTitle,
        contributors,
        translatedBookTitle,
        originalPublicationYear,
        volume,
        volumeTitle,
        seriesTitle,
        chapterPageNumbers,
        chapterTitle,
        translatedChapterTitle,
        originalDoiUrl,
        chapterType,
    } = reference;
    const chapterPageNumbersString = chapterPageNumbers ? `, p. ${chapterPageNumbers}` : '';
    const urlDoiString = urlDoi
        ? urlDoi.indexOf('10.') > -1
            ? ` <a href="https://doi.org/${urlDoi}">https://doi.org/${urlDoi}</a>`
            : ` <a href="${urlDoi}">${urlDoi}</a>`
        : '';
    const publishersString = publishers ? ` ${publishers.join('; ')}.` : '';
    const formatString = format ? ` [${format}].` : '';
    const publicationYearString = publicationYear ? ` (${publicationYear}).` : '';
    const translatedChapterTitleString = translatedChapterTitle ? ` [${translatedChapterTitle}].` : '';
    const chapterTitleString = chapterTitle
        ? ` ${chapterTitle}${translatedChapterTitleString} ${chapterType === 'republished-from-journal' ? '' : 'In'}`
        : '';
    let bookTitleString = bookTitle ? ` <i>${bookTitle}</i>` : '';
    let translatedBookTitleString = translatedBookTitle ? ` translated [${translatedBookTitle}]` : '';
    if (!bookType && translatedBookTitle) {
        bookTitleString = ` In <i>${translatedBookTitleString}</i>`;
        translatedBookTitleString = '';
    }
    const originalDoiUrlString = originalDoiUrl
        ? originalDoiUrl.indexOf('10.') > -1
            ? `, <a href="https://doi.org/${originalDoiUrl}">https://doi.org/${originalDoiUrl}</a>`
            : `, <a href="${originalDoiUrl}">${originalDoiUrl}</a>`
        : '';

    const primaryContributors = contributors
        ?.filter(c => c)
        .sort((c1, c2) => (c1.role === 'author' ? -1 : 1))
        ?.filter((c: AutoAPA.ReferenceContributor) => c.role === 'group-author' || c.role === 'author');

    const secondaryContributors = contributors
        ?.filter(c => c)
        ?.filter((c: AutoAPA.ReferenceContributor) => c.role !== 'group-author' && c.role !== 'author');

    const primaryContributorsString = formatArray(
        primaryContributors?.map(c => {
            if (c.role === 'group-author') return c?.name;
            else {
                const role =
                    c?.role === 'editor'
                        ? ` (Ed.)`
                        : c?.role === 'illustrator'
                        ? ' (Ill.)'
                        : c?.role === 'narrator'
                        ? ' (Narr.)'
                        : c?.role === 'translator'
                        ? ' (Trans.)'
                        : '';
                const last = c?.last || '';
                const first = c?.first ? `, ${c.first[0]}.` : '';
                const middle = c?.middle ? ` ${c.middle[0]}.` : '';
                const suffix = c?.suffix ? `, ${c.suffix}` : '';
                return `${last}${first}${middle}${suffix}${role}`;
            }
        }),
    );

    const secondaryContributorsString =
        secondaryContributors && secondaryContributors.length
            ? secondaryContributors
                  ?.map(c => {
                      if (c.role === 'group-author') return c?.name;
                      else {
                          const role =
                              c?.role === 'editor'
                                  ? `, Ed.`
                                  : c?.role === 'illustrator'
                                  ? ', Ill.'
                                  : c?.role === 'narrator'
                                  ? ', Narr.'
                                  : c?.role === 'translator'
                                  ? ', Trans.'
                                  : '';
                          const last = c?.last || '';
                          const first = c?.first ? `, ${c.first[0]}.` : '';
                          const middle = c?.middle ? ` ${c.middle[0]}.` : '';
                          const suffix = c?.suffix ? `, ${c.suffix}` : '';
                          return `${last}${first}${middle}${suffix}${role}`;
                      }
                  })
                  .join(';') + '; '
            : '';

    const editionString =
        edition || secondaryContributorsString || chapterPageNumbersString
            ? ` (${secondaryContributorsString}${edition} ed.${chapterPageNumbersString})`
            : '';

    const originalPublicationYearString =
        originalPublicationYear && bookType
            ? ` (Original work published ${originalPublicationYear})`
            : originalPublicationYear && !bookType && chapterType !== 'republished-from-journal'
            ? ` (Reprinted from ${originalPublicationYear})`
            : originalPublicationYear && chapterType === 'republished-from-journal'
            ? ` (Reprinted from ${originalPublicationYear}${originalDoiUrlString})`
            : '';
    const seriesTitleString = seriesTitle ? ` ${seriesTitle}:` : '';
    const volumeString = volume ? ` Vol. ${volume}.` : '';
    const volumeTitleString = volumeTitle ? ` ${volumeTitle}:` : '';
    const volumeCumulativeString =
        volumeTitle && seriesTitle ? `<i>${seriesTitleString}${volumeString}${volumeTitleString}</i>` : '';
    const referenceString =
        chapterType && chapterType === 'republished-from-journal'
            ? `${chapterTitleString}${publicationYearString} In ${primaryContributorsString}${bookTitleString}${translatedBookTitleString}${editionString}${formatString}${publishersString}${urlDoiString}${originalPublicationYearString}`
            : `${primaryContributorsString}${publicationYearString}${volumeCumulativeString}${chapterTitleString}${bookTitleString}${translatedBookTitleString}${editionString}${formatString}${publishersString}${urlDoiString}${originalPublicationYearString}`;
    return referenceString;
};

const generateReferenceStringForWebpage = (reference: AutoAPA.Reference) => {
    return 'webpage reference';
};

export const generateReferenceString = (reference: AutoAPA.Reference) => {
    const { type } = reference;
    if (type === 'book' || type === 'chapter') return generateReferenceStringForBook(reference);
    else if (type === 'webpage') return generateReferenceStringForWebpage(reference);
    else return '';
};
