export const beautifyName = (name: string) => {
    let modifiedName = name.replace('-', ' ');
    modifiedName = modifiedName.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return modifiedName;
};

export const getResourceId = (resource: string | ({ _id: string } & any)): string | undefined => {
    return typeof resource === 'string' ? resource : resource?._id;
};

export const getRepeatingSubstrings = (s: string, repeatedSubstrings: string[]): string[] => {
    const words = s.split(' ');
    // const punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
    // const spaceRE = /\s+/g;
    // s = s.replaceAll(punctRE, '').replaceAll(spaceRE, ' ');
    // console.log('S is', s);
    const substrings = new Set();
    let longestRepeatedSubstring = '';
    for (let i = 0; i < words.length; i++) {
        for (let j = i; j < words.length; j++) {
            const substring = words.slice(i, j + 1).join(' ');
            if (substrings.has(substring)) {
                if (substring.length > longestRepeatedSubstring.length) {
                    longestRepeatedSubstring = substring;
                }
            } else {
                substrings.add(substring);
            }
        }
    }

    const validLongestSubstring = longestRepeatedSubstring.trim();
    if (validLongestSubstring.split(' ').length >= 5) {
        repeatedSubstrings.push(longestRepeatedSubstring);
        const updatedtext = s.replaceAll(longestRepeatedSubstring, '');
        getRepeatingSubstrings(updatedtext, repeatedSubstrings);
    }
    return repeatedSubstrings;
};

export const random_bg_color = (): string => {
    const color = 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
    return color;
};

export const reportInProgress = (reports: Array<AutoAPA.PalagarismReport>, paperId?: string): boolean => {
    const matchingReport = reports.find(report => {
        return report.paper_id === paperId && report.status === 'pending';
    });
    return !!matchingReport;
};

export const getSuggestions = (keyword: string): string[] => {
    return [
        `What are the key technologies and techniques used for ${keyword}? `,
        `How does ${keyword} benefit businesses? `,
        `What challenges does ${keyword} pose for organizations? `,
        `What is the best way to store and analyze large datasets? `,
        `How can ${keyword} be used to create real-time analytics? `,
        `What are the security and privacy concerns associated with ${keyword}? `,
        `What is ${keyword}?`,
        `Define ${keyword}`,
        `What are the advantages of ${keyword}?`,
        `What are the applications of ${keyword}?`,
        `What are the different types of ${keyword}?`,
        `How can ${keyword} be used to improve decision making? `,
        `What are the best practices for managing and analyzing ${keyword}? `,
        `What are the most popular tools used to process ${keyword}? `,
        `What are the most common use cases for ${keyword}?`,
        `What are the key components of a successful ${keyword} strategy?`,
        `What types of data should be included in a ${keyword} system?`,
        `What are the challenges associated with ${keyword} analysis?`,
        `How can ${keyword} be used to drive business decisions?`,
        `What are the benefits of using ${keyword} analytics?`,
        `What are the best practices for managing and storing large amounts of data?`,
        `What are the security and privacy considerations when dealing with ${keyword}?`,
        `What technologies are needed to support a ${keyword} project?`,
        `How can ${keyword} be used to gain valuable insights into customer behavior?`,
        `What tools are available to help manage and analyze ${keyword}?`,
        `What are the major challenges associated with collecting and managing ${keyword}? `,
        `How does ${keyword} provide insights that wouldn't be found with traditional data analysis?`,
        `What are the benefits of using ${keyword} analytics? `,
        `What are the potential applications of ${keyword} in various industries? `,
        `How can organizations ensure that they are compliant with data privacy and security regulations when using ${keyword}? `,
        `What type of technology is needed to collect, store, and analyze ${keyword}? `,
        `What are the best practices for extracting value from ${keyword}? `,
        `What types of data should organizations be collecting to gain the most insights from ${keyword}? `,
        `How can businesses ensure that they are leveraging ${keyword} for competitive advantage? `,
        `How can ${keyword} be used to create predictive models and uncover hidden trends?`,
        `What are the benefits of leveraging ${keyword} for businesses?`,
        `What are the challenges associated with processing ${keyword}?`,
        `How is ${keyword} different from traditional data analysis?`,
        `What are the ethical considerations of using ${keyword}?`,
        `How is ${keyword} being used in the healthcare industry?`,
        `What tools and technologies are needed to manage ${keyword}?`,
        `What are some best practices for collecting and storing ${keyword}?`,
        `What kind of insights can ${keyword} provide to organizations?`,
        `How can organizations protect the privacy of ${keyword}?`,
        `How can ${keyword} be used to drive innovation?`,
        `Introduction to ${keyword}`,
        `Conclusion on ${keyword}`,
        `Overview on ${keyword}`,
        `${keyword} is a term that describes`,
        `${keyword} primarily refers to`,
        `The definition of ${keyword} is`,
        `${keyword} can be described in terms of`,
    ];
};

export const getRandom = (arr: Array<any>, n: number): Array<any> => {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len) throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

export const getAllSubjects = () => {
    return [
        'General',
        'Emerging Threats',
        'Business Intelligence',
        'Security Architecture',
        'Org Behaviour & Leadership',
        'Managerial Economics',
        'Enterprise Risk Management',
        'Project Management',
        'Managerial Accounting',
        'Marketing Communication',
        'Marketing Management',
        'Big Data',
        'Cloud Computing',
        'Managerial Finance',
        'Cryptography',
        'Busi. Continuity & Disaster Plan',
        'Access Control',
        'Adv. Programming Languages',
        'Adv. Computer Networking',
        'Blockchain & Cryptocurrency',
        'C&E',
        'Cyber Law',
        'Cyber Security',
        'Foundations',
        'Physical Security',
        'Global Policies',
        'Health Finance',
        'Information Governance',
        'ITM Capstone',
        'Management Info System',
        'Operational Excellence',
        'Business Ethics',
        'Information Systems',
        'Negotiation',
        'Info. Security & Risk Mgmt',
        'Telecom Security',
        'Intro to Data Mining',
        'Application Security',
        'Team Management',
        'Legal',
        'Business Sustain. & Analysis',
        'Initiating the Project',
        'Operation Security',
        'Business Research Methods',
        'Human Computer Usability',
        'Database Systems',
        'Big Data 2',
        'System Analysis and Design',
        'Corp strategy,MGMT & Leadership',
        'Ethical Strategic Management',
        'Data Warehouse & Mining',
    ].sort();
};

export const getCookieOptions = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const cookieOptions = {
        expires: expirationDate,
        domain: process.env.COOKIE_DOMAIN,
        // domain: '.7thtowertech.com',
        // domain: process.env.ENVIRONMENT_FLAG ? process.env.ETC_DEPLOYMENT_DOMAIN : process.env.ETC_DEPLOYMENT_DOMAIN,
        httpOnly: false,
    };
    return cookieOptions;
};

export const getCookie = (cookieName: string) => {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];
};

export const getRecommendedSubjects = () => {
    return ['General', 'Big Data', 'Database Systems', 'Business Intelligence'];
};
