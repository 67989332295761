import React from 'react';

import { Helmet } from 'react-helmet';

const Head = ({ title }: { title: string }) => {
    return (
        <>
            <Helmet title={`${title} | Auto APA`} defaultTitle="Auto APA" />
        </>
    );
};

export default Head;
