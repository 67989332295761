import { notification } from 'antd';
import { navigate } from 'gatsby';
import { Cookies } from 'react-cookie';
import LocalizedStrings from 'react-localization';

import { getCookieOptions } from './misc';

const cookies = new Cookies();

const strings = new LocalizedStrings({
    en: {
        tokenExpired: 'Your session has expired. Please login again.',
        success: 'Success!',
        error: 'Error!',
    },
    de: {
        tokenExpired: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
        success: 'Erfolgreich!!',
        error: 'Fehler!!',
    },
});

export const showNotification = (
    type: 'success' | 'info' | 'warning' | 'error',
    title?: string,
    description?: string,
) => {
    notification[type]({
        duration: 3,
        message: title,
        description: description,
    });
};

export const handleError = (err: any) => {
    if (typeof err === 'object') {
        const typedError = err as APIRequester.APIError & Error;
        if (typedError.code === 'TOKEN_EXPIRED') {
            showNotification('error', 'Error!', strings.tokenExpired);
            cookies.remove('authToken', getCookieOptions());
            navigate('/login');
        } else if (typedError.code === 'INVALID_TOKEN') {
            cookies.remove('authToken', getCookieOptions());

            navigate('/login');
        } else
            showNotification(
                'error',
                strings.error,
                typedError.extra
                    ? typedError.extra
                    : typedError.description
                    ? typedError.description
                    : typedError.message
                    ? typedError.message
                    : typedError.code,
            );
    } else if (err.message) showNotification('error', strings.error, err.message);
    console.error(err);
};

export const handleSuccess = (message: string) => {
    showNotification('success', strings.success, message);
};
