import axios from 'axios';
import dotenv from 'dotenv';
import { navigate } from 'gatsby';
import { Cookies, useCookies } from 'react-cookie';
import store from 'store';

import { getCookie, getCookieOptions } from './misc';

dotenv.config();

const API_BASE_URL = process.env.API_URL || 'http://localhost:3000';
const API_BASE_URL2 = process.env.API_URL2;
const PLAG_API_URL = process.env.PLAG_API_URL;

const extractAPIError = (res: any) => {
    if (res?.response?.data) return res.response.data;
    else return res;
};

const extractAPIData = (res: any) => {
    if (res?.data) return res.data;
    else return res;
};

const cookies = new Cookies();

// Token refresh interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const errorCode = error.response?.data?.code;
        if (errorCode === 'TOKEN_EXPIRED' || errorCode === 'INVALID_TOKEN') {
            // Clear storage and logout
            cookies.remove('authToken', getCookieOptions());
            cookies.remove('refreshToken', getCookieOptions());
            cookies.remove('user', getCookieOptions());
            navigate('/login');
        } else return Promise.reject(extractAPIError(error));
    },
);

axios.interceptors.request.use(
    config => {
        const token = getCookie('authToken');
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

export const htmlToDocx = async ({
    htmlString,
    runningHeadTitle,
}: {
    htmlString: string;
    runningHeadTitle: string;
}) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/html-to-docx`,
            { htmlString, runningHeadTitle },
            {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const htmlToPdf = async ({ htmlString, runningHeadTitle }: { htmlString: string; runningHeadTitle: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/html-to-pdf`,
            { htmlString, runningHeadTitle },
            {
                headers: { 'Content-Type': 'application/json' },
                responseType: 'blob',
            },
        );
        return extractAPIData(res) as Blob;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const countPages = async ({
    htmlString,
    runningHeadTitle,
}: {
    htmlString: string;
    runningHeadTitle: string;
}) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/count-pages`,
            { htmlString, runningHeadTitle },
            {
                headers: { 'Content-Type': 'application/json' },
            },
        );
        return extractAPIData(res) as number;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const emailPdf = async ({
    emailId,
    htmlString,
    subject,
    runningHeadTitle,
}: {
    emailId: string;
    htmlString: string;
    subject: string;
    runningHeadTitle: string;
}) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/email-pdf`,
            { emailId, htmlString, subject, runningHeadTitle },
            {
                headers: { 'Content-Type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getLocalUser = async ({ userId }: { userId: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/auth/local-user`,
            { userId },
            {
                headers: { 'Content-Type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const emailDocx = async ({
    emailId,
    docxBlob,
    subject,
}: {
    emailId: string;
    docxBlob: Blob;
    subject: string;
}) => {
    try {
        const formData = new FormData();
        formData.append('emailId', emailId);
        formData.append('subject', subject);
        formData.append('docxBlob', docxBlob);
        const res = await axios.post(`${API_BASE_URL}/utility/email-docx`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getTocFromPdf = async ({ pdf, titles }: { pdf: Blob; titles: any }) => {
    try {
        const bodyFormData = new FormData();
        bodyFormData.append('file', pdf);
        bodyFormData.append('titles', JSON.stringify(titles));
        const res = await axios({
            method: 'post',
            url: `${API_BASE_URL}/utility/generate-toc-from-pdf`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const googleScholarSearchKeyword = async ({
    keyword,
    start = 20,
    since,
}: {
    keyword: string;
    start?: number;
    since?: string;
}) => {
    try {
        const params = new URLSearchParams(
            JSON.parse(
                JSON.stringify({
                    q: keyword,
                    start: start.toString(),
                    since,
                }),
            ),
        ).toString();
        const res = await axios.get(`${API_BASE_URL}/utility/google-scholar-search?${params}`);
        return extractAPIData(res) as APIResponse.GoogleScholarLookupResponse;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const googleScholarCitationSearch = async ({ resultId }: { resultId: string }) => {
    try {
        const params = new URLSearchParams({
            q: resultId,
        }).toString();
        const res = await axios.get(`${API_BASE_URL}/utility/google-scholar-cite?${params}`);
        return extractAPIData(res) as APIResponse.GoogleScholarCitationSearchResponse;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const referenceToBibTex = async (apa: string) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/apa-to-bibtex`,
            { apa },
            {
                headers: { 'Content-Type': 'application/json' },
            },
        );
        return extractAPIData(res) as { output?: string };
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

// Login
export const login = async (params: { emailId: string; password: string }) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/auth/login`, params, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as APIRequester.LoginResponse;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const forgotPassword = async (params: { emailId: string }) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/auth/forgot-password`, params, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as APIRequester.ForgotPasswordResponse;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const resetPassword = async (params: { newPassword: string; token: string | null }) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/auth/reset-password`, params, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as APIRequester.ResetPasswordResponse;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const createUser = async (user: Users.User) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/users`, user, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const listUsers = async () => {
    try {
        const res = await axios.get(`${API_BASE_URL}/users`, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as Users.User[];
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const deleteUser = async (_id: Users.User['_id']) => {
    try {
        const res = await axios.delete(`${API_BASE_URL}/users/${_id}`, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getNewAccessToken = async (refreshToken: string) => {
    try {
        const token = getCookie('authToken');
        const res = await axios.post(`https://auth.etutordev.com/api/refresh-token?refresh_token=${refreshToken}`, {
            headers: { 'content-type': 'application/json', authorization: `Bearer ${token}` },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getAllPapers = async (options?: {
    skip?: number;
    limit?: number;
    text?: string;
    type?: 'own' | 'shared';
}) => {
    try {
        const res = await axios.get(
            `${API_BASE_URL}/papers?skip=${options?.skip || 0}&limit=${options?.limit || 0}&type=${
                options?.type || 'own'
            }&text=${options?.text || ''}`,
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res) as { papers: Papers.Paper[]; total: number; limit: number; skip: number };
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const checkPlagarism = async (pid: string, content: string) => {
    return axios.post(`${API_BASE_URL}/papers/${pid}/plagarism-checker`, {
        headers: { 'content-type': 'application/json' },
        data: { content },
    });
};

export const createPaper = async (paper: Papers.Paper): Promise<Papers.Paper> => {
    try {
        const res = await axios.post(`${API_BASE_URL}/papers`, paper, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as Papers.Paper;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const deletePaper = async (pid: string) => {
    try {
        const res = await axios.delete(`${API_BASE_URL}/papers/${pid}`, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getPaper = async (pid: string) => {
    try {
        const res = await axios.get(`${API_BASE_URL}/papers/${pid}`, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res) as Papers.Paper;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const savePaper = async (pid: string, paper: Papers.Paper) => {
    try {
        const referenceIds = paper.references?.map(r => r._id);
        delete paper['sharedWith'];
        const res = await axios.put(
            `${API_BASE_URL}/papers/${pid}`,
            { ...paper, references: referenceIds },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const createReference = async (reference: Papers.Reference) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/references`, reference, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const proxyUrl = async <T>(url: string) => {
    try {
        const res = await axios.get(`${API_BASE_URL}/utility/get-url?url=${encodeURIComponent(url)}`);
        return extractAPIData(res) as T;
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const sharePaper = async ({ email, paperId }: { email: string; paperId: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/papers/${paperId}/share`,
            { email },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const unsharePaper = async ({ email, paperId }: { email: string; paperId: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/papers/${paperId}/unshare`,
            { email },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const addComment = async ({ paperId, comment }: { comment: string; paperId: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/papers/${paperId}/comment`,
            { comment },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const changeStatus = async ({ paperId, newStatus }: { newStatus: Papers.PAPER_STATUS; paperId: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/papers/${paperId}/status`,
            { newStatus },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res);
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const getEmailSuggestions = async ({ text }: { text: string }) => {
    try {
        const res = await axios.post(
            `${API_BASE_URL}/utility/email-suggestions`,
            { text },
            {
                headers: { 'content-type': 'application/json' },
            },
        );
        return extractAPIData(res) as string[];
    } catch (error) {
        return Promise.reject(extractAPIError(error));
    }
};

export const generateText = async (text: string, buttonName: string, emailId: string, name: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('text', text);
    bodyFormData.append('buttonName', buttonName);
    bodyFormData.append('email', emailId);
    bodyFormData.append('displayName', name);

    try {
        const res = await axios.post(`${API_BASE_URL2}`, bodyFormData, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        Promise.reject(extractAPIError(error));
    }
};
export const getGeneratedTextData = async (token: string) => {
    try {
        const res = await axios.get(`${PLAG_API_URL}/textgen/${token}`, {
            headers: { 'content-type': 'application/json' },
        });
        return extractAPIData(res);
    } catch (error) {
        Promise.reject(extractAPIError(error));
    }
};

export const apiRequester = {
    htmlToDocx,
    login,
    forgotPassword,
    resetPassword,
    referenceToBibTex,
    googleScholarCitationSearch,
    googleScholarSearchKeyword,
    getTocFromPdf,
    htmlToPdf,
    emailDocx,
    emailPdf,
    createUser,
    listUsers,
    deleteUser,
    createPaper,
    deletePaper,
    getAllPapers,
    savePaper,
    getPaper,
    createReference,
    proxyUrl,
    sharePaper,
    unsharePaper,
    getEmailSuggestions,
    countPages,
    addComment,
    changeStatus,
    generateText,
    getGeneratedTextData,
    checkPlagarism,
    getLocalUser,
    getNewAccessToken,
};
