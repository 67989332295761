/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useContext, useEffect, useState } from 'react';

import { message } from 'antd';
import {
    AlignmentType,
    Document,
    Header,
    ISectionOptions,
    ISectionPropertiesOptions,
    LineRuleType,
    Packer,
    PageNumber,
    Paragraph,
    TextRun,
    Table,
    TableRow,
    TableCell,
    WidthType,
    BorderStyle,
    ImageRun,
    PageBreak,
    TableLayout,
    TableLayoutType,
    TabStopType,
    TabStopPosition,
    convertInchesToTwip,
    VerticalAlign,
    HeightRule,
    LevelSuffix,
    LevelFormat,
} from 'docx';
import { navigate } from 'gatsby';
import { parse, HTMLElement, Node as ParserNode } from 'node-html-parser';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import store from 'store';

import Page from '../components/Page';
import TitlePage from '../components/TitlePage';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess, sharePaper } from '../utility';

export const usePaper = () => {
    const context = useContext(GlobalContext);
    const [paperLoading, setPaperLoading] = useState(false);

    useEffect(() => {
        const activePaper = store.get('activePaper');
        if (activePaper) context.setActivePaper(activePaper);
    }, []);

    useEffect(() => {
        if (context.activePaper) {
            context.setTitlePage({ ...context.activePaper });
        }
    }, [context.activePaper]);

    const getPaper = async (pid: string) => {
        try {
            setPaperLoading(true);
            const paper = await apiRequester.getPaper(pid);
            return paper;
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const updateTitlePage = (values: AutoAPA.TitlePage, refreshHtml = true) => {
        const titlePageComponent = (
            <Page id="titlePage">
                <TitlePage {...values} />
            </Page>
        );
        const tilePageNode = parse(ReactDOMServer.renderToString(titlePageComponent));
        const root = parse(context.html);
        if (root && root.childNodes && root.childNodes.length) {
            const childNodes = root.childNodes[0].childNodes as (ParserNode & {
                rawTagName?: string;
                classNames?: string[];
                id?: string;
            })[];
            // Replace title page
            root.childNodes[0].childNodes = childNodes.map(childNode => {
                if (
                    childNode.rawTagName === 'div' &&
                    childNode.classNames?.includes('page') &&
                    childNode.id === 'titlePage'
                ) {
                    return tilePageNode;
                } else return childNode;
            });
            refreshHtml && context.setHtml(root.innerHTML);
        }
        context.setTitlePage(values);
    };

    const loadPaper = async () => {
        const paper = (await getPaper(context.activePaper?._id!)) as Papers.Paper;
        store.set('activePaper', paper);
        context.setActivePaper(paper);
    };

    const noBorder = {
        top: {
            style: BorderStyle.NIL,
            size: 0,
        },
        bottom: {
            style: BorderStyle.NIL,
            size: 0,
        },
        left: {
            style: BorderStyle.NIL,
            size: 0,
        },
        right: {
            style: BorderStyle.NIL,
            size: 0,
        },
    };

    const fullTableBorder = {
        top: {
            style: BorderStyle.SINGLE,
            size: 1,
        },
        bottom: {
            style: BorderStyle.SINGLE,
            size: 1,
        },
        left: {
            style: BorderStyle.SINGLE,
            size: 1,
        },
        right: {
            style: BorderStyle.SINGLE,
            size: 1,
        },
    };

    const refreshPapers = async () => {
        try {
            setPaperLoading(true);
            const {
                total: total = 0,
                limit: limit = 0,
                skip: skip = 0,
                papers: papers = [],
            } = (await apiRequester.getAllPapers({
                limit: context.paperPagination.limit,
                skip: context.paperPagination.skip,
                text: context.paperSearch,
                type: context.paperListType,
            })) || { total: 0, limit: 0, skip: 0, papers: [] };
            context.setPapers(papers);
            context.setPaperPagination(pagination => ({ ...pagination, total }));
            return papers;
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
        return [];
    };

    const createPaper = async (paper: Papers.Paper) => {
        try {
            setPaperLoading(true);
            const newPaper = await apiRequester.createPaper(paper);
            handleSuccess('Paper has been created');
            context.setActivePaper(newPaper);
            store.set('activePaper', newPaper);
            navigate('/editor');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const deletePaper = async (pid: string) => {
        try {
            setPaperLoading(true);
            await apiRequester.deletePaper(pid);
            handleSuccess('Paper has been deleted');
            await refreshPapers();
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const getPage = (root: HTMLElement, selector: string) => {
        const pageNode = root.querySelectorAll(selector);
        const htmlOfNodes = pageNode.length > 0 ? pageNode.map(node => node.innerHTML) : [];
        const completePageNode = htmlOfNodes.map(html =>
            parse(
                renderToStaticMarkup(
                    <div
                        className="page"
                        style={{ pageBreakAfter: 'always' }}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />,
                ),
            ),
        );
        return completePageNode;
    };

    const getChildren = (
        parent: HTMLElement,
        options = { bold: false, isReference: false, noIndent: false } as {
            bold?: boolean;
            isReference?: boolean;
            noBorder?: boolean;
            noIndent?: boolean;
            unorderedBulletLevel?: number;
            orderedBulletLevel?: number;
        },
    ) => {
        const children: any[] = [];
        const childNodes: HTMLElement[] = parent.childNodes as HTMLElement[];
        const parentTagName = parent.tagName;
        childNodes.map(childNode => {
            const nodeType = childNode.nodeType;
            const tagName = childNode.tagName;
            const attrs = childNode.attrs;
            const style = attrs?.style;
            const source = attrs?.src;
            const classNames = childNode.classNames;
            if (tagName === 'DIV') {
                const noIndent = classNames.includes('no-indent');
                if (childNode.childNodes.length && childNode.innerText !== '' && childNode.innerText !== '&nbsp;')
                    children.push(...getChildren(childNode, { noIndent }));
            } else if (tagName === 'P') {
                const indent = classNames.includes('no-indent') || options.noIndent ? 0 : 1;
                const isReference = style?.includes('text-indent: -36px;');
                const isCenter = style?.includes('text-align: center;') || classNames.includes('text-center');
                children.push(
                    new Paragraph({
                        children: getChildren(childNode),
                        alignment: isCenter ? AlignmentType.CENTER : undefined,
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                        indent: {
                            firstLine:
                                isReference || !indent || context.activePaper?.type === 'discussion'
                                    ? undefined
                                    : convertInchesToTwip(0.5),
                            hanging: isReference ? convertInchesToTwip(0.5) : undefined,
                            left: isReference ? convertInchesToTwip(0.5) : undefined,
                        },
                    }),
                );
            } else if (tagName === 'IMG') {
                children.push(
                    new Paragraph({
                        children: [
                            new ImageRun({
                                data: source,
                                transformation: {
                                    width: 200,
                                    height: 100,
                                },
                            }),
                        ],
                    }),
                );
            } else if (tagName === 'BR' && parentTagName === 'P') children.push(new TextRun({ break: 1, size: 24 }));
            else if (tagName === 'BR')
                children.push(
                    new Paragraph({
                        children: [new TextRun({ size: 24, text: '\n' })],
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                    }),
                );
            else if (nodeType === Node.TEXT_NODE && childNode.text !== '\n' && childNode.text !== '&nbsp;') {
                if (options.unorderedBulletLevel !== undefined)
                    children.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: childNode.text,
                                    size: 24,
                                    font: 'Times New Roman',
                                }),
                            ],
                            bullet: {
                                level: options.unorderedBulletLevel,
                            },
                            spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                        }),
                    );
                else if (options.orderedBulletLevel !== undefined)
                    children.push(
                        new Paragraph({
                            children: [
                                new TextRun({
                                    text: childNode.text,
                                    size: 24,
                                    font: 'Times New Roman',
                                }),
                            ],
                            numbering: {
                                level: options.orderedBulletLevel,
                                reference: 'bullet-numbering',
                            },
                            spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                        }),
                    );
                else
                    children.push(
                        new TextRun({
                            text: childNode.text,
                            size: 24,
                            font: 'Times New Roman',
                            bold: options.bold,
                        }),
                    );
            } else if (tagName === 'I') {
                children.push(
                    new TextRun({
                        text: childNode.text,
                        size: 24,
                        font: 'Times New Roman',
                        italics: true,
                    }),
                );
            } else if (tagName === 'STRONG') {
                children.push(...getChildren(childNode, { bold: true }));
            } else if (tagName === 'H1')
                children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: childNode.text,
                                size: 24,
                                font: 'Times New Roman',
                                bold: true,
                            }),
                        ],
                        alignment: AlignmentType.CENTER,
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                    }),
                );
            else if (tagName === 'H2')
                children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: childNode.text,
                                size: 24,
                                font: 'Times New Roman',
                                bold: true,
                            }),
                        ],
                        alignment: AlignmentType.LEFT,
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                    }),
                );
            else if (tagName === 'H3')
                children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: childNode.text,
                                size: 24,
                                font: 'Times New Roman',
                                bold: true,
                                italics: true,
                            }),
                        ],
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                    }),
                );
            else if (tagName === 'H4')
                children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: childNode.text,
                                size: 24,
                                font: 'Times New Roman',
                                bold: true,
                            }),
                        ],
                        indent: { left: convertInchesToTwip(0.5) },
                        spacing: { line: 480, lineRule: LineRuleType.AUTO, afterAutoSpacing: true },
                    }),
                );
            else if (tagName === 'H5')
                children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: childNode.text,
                                size: 24,
                                font: 'Times New Roman',
                                bold: true,
                                italics: true,
                            }),
                        ],
                        indent: { left: convertInchesToTwip(0.5) },
                        spacing: {
                            line: convertInchesToTwip(0.5),
                            lineRule: LineRuleType.AUTO,
                            afterAutoSpacing: true,
                        },
                    }),
                );
            else if (tagName === 'SPAN') {
                const isReference = classNames.includes('csl-entry');
                children.push(...getChildren(childNode, { isReference }));
            } else if (tagName === 'TABLE') {
                const noBorder = classNames.includes('no-border');
                const rows = getChildren(childNode, { noBorder: false });
                const table = new Table({
                    rows,
                    width: { size: 100, type: WidthType.PERCENTAGE },
                    layout: TableLayoutType.FIXED,
                });
                children.push(table);
                children.push(new Paragraph({ children: [new TextRun({ break: 1, size: 24 })] }));
            } else if (tagName === 'TBODY') {
                const tbody = getChildren(childNode, options);
                children.push(...tbody);
            } else if (tagName === 'TR') {
                const tr = new TableRow({
                    cantSplit: true,
                    children: getChildren(childNode, options),
                    height: { value: 480, rule: HeightRule.EXACT },
                });
                children.push(tr);
            } else if (tagName === 'TD') {
                const isLeftIndent = classNames.includes('indent-left') ? 1 : 0;
                const colSpan = attrs.colspan ? parseInt(attrs.colspan!) : 1;
                const leftIndent = isLeftIndent
                    ? parseInt(
                          classNames
                              .split(' ')
                              .find(x => x.startsWith('indent-left-'))
                              ?.split('-')[2]!,
                      ) - 1
                    : 0;
                const hasTextAlign = classNames.includes('text-align') ? 1 : 0;
                const textAlign = hasTextAlign
                    ? classNames
                          .split(' ')
                          .find(x => x.startsWith('text-align'))
                          ?.split('-')[2]
                    : 'left';
                const td = new TableCell({
                    verticalAlign: VerticalAlign.CENTER,
                    borders: options.noBorder ? noBorder : fullTableBorder,
                    children: [
                        new Paragraph({
                            children: getChildren(childNode),
                            indent: { left: 240 * leftIndent },
                            alignment:
                                textAlign === 'left'
                                    ? AlignmentType.LEFT
                                    : textAlign === 'center'
                                    ? AlignmentType.CENTER
                                    : textAlign === 'right'
                                    ? AlignmentType.RIGHT
                                    : AlignmentType.LEFT,
                        }),
                    ],
                    columnSpan: colSpan,
                });
                children.push(td);
            } else if (tagName === 'UL') {
                children.push(
                    ...getChildren(childNode, {
                        ...options,
                        unorderedBulletLevel:
                            options.unorderedBulletLevel !== undefined ? options.unorderedBulletLevel + 1 : 0,
                    }),
                );
            } else if (tagName === 'OL') {
                children.push(
                    ...getChildren(childNode, {
                        ...options,
                        orderedBulletLevel:
                            options.orderedBulletLevel !== undefined ? options.orderedBulletLevel + 1 : 0,
                    }),
                );
            } else if (tagName === 'LI') {
                children.push(...getChildren(childNode, options));
            }
        });
        return children;
    };

    const getCleanHtml = (dirtyHtml: string, target?: string) => {
        if (dirtyHtml) {
            const root = parse(dirtyHtml);
            const newRoot = parse(
                `<div style='font-family: "Times New Roman", Times, serif; font-size: 12px; color: black;'></div>`,
            );
            const completeTitlePageNodes = getPage(root, '.titlePage');
            const completeAbstractPageNodes = getPage(root, '.abstractPage');
            const completeConclusionPageNodes = getPage(root, '.conclusionPage');
            const tableOfContentPageNodes = getPage(root, '.tableOfContentsPage');
            const completeContentPageNodes = getPage(root, '.contentPage');
            const completeReferencePageNodes = getPage(root, '.referencePage');
            const completeAppendixPageNodes = getPage(root, '.appendixPage');

            const paperType = context.activePaper?.type;
            const showTitlePage = !paperType || ['research', 'assignment'].includes(paperType!);
            const showAbstractPage = !paperType || ['research'].includes(paperType!);
            const showContentPage = !paperType || ['research', 'assignment', 'discussion'].includes(paperType!);
            const showConclusionPage = !paperType || ['research'].includes(paperType!);
            const showTableOfContentsPage = !paperType || ['research'].includes(paperType!);
            const showReferencePage = !paperType || ['research', 'assignment'].includes(paperType!);
            const showAppendingPage = !paperType || ['research'].includes(paperType!);

            if (showTitlePage) {
                newRoot.childNodes[0].childNodes.push(...completeTitlePageNodes);
            }

            if (showTableOfContentsPage) {
                newRoot.childNodes[0].childNodes.push(...tableOfContentPageNodes);
            }

            if (showAbstractPage) {
                newRoot.childNodes[0].childNodes.push(...completeAbstractPageNodes);
            }

            if (showContentPage) {
                newRoot.childNodes[0].childNodes.push(...completeContentPageNodes);
            }

            if (showConclusionPage) {
                newRoot.childNodes[0].childNodes.push(...completeConclusionPageNodes);
            }

            if (showReferencePage) {
                newRoot.childNodes[0].childNodes.push(...completeReferencePageNodes);
            }

            if (showAppendingPage) {
                newRoot.childNodes[0].childNodes.push(...completeAppendixPageNodes);
            }

            // Force add line height to all elements
            const paragraphElements = root.querySelectorAll('*');
            paragraphElements.map(element => {
                const style = element.getAttribute('style');
                element.setAttribute('style', `line-height: 2; ${style}`);
            });
            const returnHtml = `<html><head>
            <link rel="preconnect" href="https://fonts.gstatic.com">
            <link href="https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;500;600;700" rel="stylesheet">
            <style>
            td{
                font-size: 12px;
            }
            </style>
            </head><body>${newRoot.innerHTML}</body></html>`
                .replaceAll(/<h3>/gi, `<h3 style='font-family: "Times New Roman", Times, serif; color: black;'>`)
                .replaceAll(/<em>/gi, '<i>')
                .replaceAll(/<\/em>/gi, '</i>');
            return returnHtml;
        } else return '';
    };

    const savePaper = async () => {
        try {
            setPaperLoading(true);
            const activePaper = context.activePaper;
            const pid = activePaper?._id!;
            const html = context.html;
            const titlePage = context.titlePage;
            getCleanHtml(html);
            const paper: AutoAPA.TitlePage & Papers.Paper = {
                ...titlePage,
                rawHtml: html,
                references: activePaper?.references,
            };
            delete paper['_id'];
            delete paper['__v'];
            delete paper['owner'];
            delete paper['createdAt'];
            delete paper['updatedAt'];
            delete paper['status'];
            delete paper['events'];
            await apiRequester.savePaper(pid, paper);
            message.success('Paper has been auto saved.');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const sharePaperWithEmail = async (data: { paperId: string; email: string }) => {
        try {
            setPaperLoading(true);
            await apiRequester.sharePaper(data);
            const papers = await refreshPapers();
            const activePaper = context.activePaper;
            const refreshActivePaper = papers.find(paper => paper._id === activePaper?._id);
            if (refreshActivePaper) {
                context.setActivePaper(activePaper => {
                    return { ...{ ...activePaper, rawHtml: context.html }, sharedWith: refreshActivePaper.sharedWith };
                });
            }
            const sharePaper = context.sharePaper;
            const refreshSharePaper = papers.find(paper => paper._id === sharePaper?._id);
            if (refreshSharePaper) {
                context.setSharePaper(sharePaper => {
                    return { ...sharePaper, sharedWith: refreshSharePaper.sharedWith };
                });
            }
            handleSuccess('Paper has been shared');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const removePaperSharedWithEmail = async (data: { paperId: string; email: string }) => {
        try {
            setPaperLoading(true);
            await apiRequester.unsharePaper(data);
            const papers = await refreshPapers();
            const activePaper = context.activePaper;
            const refreshActivePaper = papers.find(paper => paper._id === activePaper?._id);
            if (refreshActivePaper) {
                context.setActivePaper(activePaper => {
                    return { ...{ ...activePaper, rawHtml: context.html }, sharedWith: refreshActivePaper.sharedWith };
                });
            }
            const sharePaper = context.sharePaper;
            const refreshSharePaper = papers.find(paper => paper._id === sharePaper?._id);
            if (refreshSharePaper) {
                context.setSharePaper(sharePaper => {
                    return { ...sharePaper, sharedWith: refreshSharePaper.sharedWith };
                });
            }
            handleSuccess('Paper has been unshared');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const addComment = async (data: { paperId: string; comment: string }) => {
        try {
            setPaperLoading(true);
            await apiRequester.addComment(data);
            const papers = await refreshPapers();
            const activePaper = context.activePaper;
            const refreshActivePaper = papers.find(paper => paper._id === activePaper?._id);
            if (refreshActivePaper) {
                context.setActivePaper(activePaper => {
                    return { ...activePaper, sharedWith: refreshActivePaper.sharedWith };
                });
            }
            const reviewPaper = context.reviewPaper;
            const refreshSharePaper = papers.find(paper => paper._id === reviewPaper?._id);
            if (refreshSharePaper) {
                context.setReviewPaper(reviewPaper => {
                    return { ...reviewPaper, events: refreshSharePaper.events, status: refreshSharePaper.status };
                });
            }
            handleSuccess('Comment has been added');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const changeStatus = async (data: { paperId: string; newStatus: Papers.PAPER_STATUS }) => {
        try {
            setPaperLoading(true);
            await apiRequester.changeStatus(data);
            const papers = await refreshPapers();
            const activePaper = context.activePaper;
            const refreshActivePaper = papers.find(paper => paper._id === activePaper?._id);
            if (refreshActivePaper) {
                context.setActivePaper(activePaper => {
                    return { ...activePaper, sharedWith: refreshActivePaper.sharedWith };
                });
            }
            const reviewPaper = context.reviewPaper;
            const refreshSharePaper = papers.find(paper => paper._id === reviewPaper?._id);
            if (refreshSharePaper) {
                context.setReviewPaper(reviewPaper => {
                    return { ...reviewPaper, events: refreshSharePaper.events, status: refreshSharePaper.status };
                });
            }
            handleSuccess('Paper status has been changed');
        } catch (err) {
            handleError(err);
        } finally {
            setPaperLoading(false);
        }
    };

    const getDocument = (html: string) => {
        const root = parse(html);
        const pages = root.querySelectorAll('.page');

        const section: ISectionOptions = {
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            tabStops: [
                                {
                                    type: TabStopType.RIGHT,
                                    position: TabStopPosition.MAX,
                                },
                            ],
                            children: [
                                new TextRun({
                                    text: context.titlePage?.runningHead?.toUpperCase() || '',
                                    size: 24,
                                }),
                                new TextRun({
                                    text: `\t`,
                                }),
                                new TextRun({
                                    children: [PageNumber.CURRENT],
                                    size: 24,
                                }),
                            ],
                        }),
                    ],
                }),
            },
            properties: {
                page: {
                    size: { height: '297mm', width: '210mm' },
                    margin: { left: '25.4mm', right: '25.4mm', top: '25.4mm', bottom: '25.4mm' },
                },
            },
            children: [],
        };

        pages.map((page, index) => {
            const children = getChildren(page);
            //@ts-ignore
            section.children.push(...children);
            const isLastPage = index === pages.length - 1;
            //@ts-ignore
            !isLastPage && section.children.push(new Paragraph({ children: [new PageBreak()] }));
        });

        const document = new Document({
            title: context.activePaper?.title,
            creator: context.activePaper?.author || '',
            sections: [section],
            numbering: {
                config: [
                    {
                        reference: 'bullet-numbering',
                        levels: [
                            {
                                level: 0,
                                text: '%1.',
                                format: LevelFormat.DECIMAL,
                                alignment: AlignmentType.START,
                                suffix: LevelSuffix.TAB,
                                style: {
                                    paragraph: {
                                        indent: { left: 720, hanging: 240 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                text: '%2.',
                                format: LevelFormat.DECIMAL,
                                alignment: AlignmentType.START,
                                suffix: LevelSuffix.TAB,
                                style: {
                                    paragraph: {
                                        indent: { left: 1440, hanging: 240 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                text: '%3.',
                                format: LevelFormat.DECIMAL,
                                alignment: AlignmentType.START,
                                suffix: LevelSuffix.TAB,
                                style: {
                                    paragraph: {
                                        indent: { left: 2160, hanging: 240 },
                                    },
                                },
                            },
                            {
                                level: 3,
                                text: '%4.',
                                format: LevelFormat.DECIMAL,
                                alignment: AlignmentType.START,
                                suffix: LevelSuffix.TAB,
                                style: {
                                    paragraph: {
                                        indent: { left: 2880, hanging: 240 },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        });

        return document;
    };

    return {
        paperLoading,
        createPaper,
        papers: context.papers,
        deletePaper,
        activePaper: context.activePaper,
        setActivePaper: context.setActivePaper,
        refreshPapers,
        savePaper,
        updateTitlePage,
        loadPaper,
        sharePaperWithEmail,
        removePaperSharedWithEmail,
        getChildren,
        getCleanHtml,
        getDocument,
        addComment,
        changeStatus,
    };
};

export default usePaper;
