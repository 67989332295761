import React from 'react';

import moment from 'moment';

export const TitlePage = (values: AutoAPA.TitlePage) => {
    return (
        <>
            <div
                id="titlePage"
                className="mceNonEditable titlePage"
                contentEditable={false}
                style={{ outline: 'none', lineHeight: 2, position: 'relative' }}
            >
                <br />
                <br />
                <br />
                <br />
                <p style={{ textAlign: 'center', lineHeight: 2 }} className="text-center no-indent">
                    <strong>{values.title}</strong>
                </p>
                <br />
                <p style={{ textAlign: 'center', lineHeight: 2 }} className="no-indent">
                    {values.author && (
                        <>
                            {values.author}
                            <br />
                        </>
                    )}
                    {values.institution && (
                        <>
                            {values.institution}
                            <br />
                        </>
                    )}
                    {values.courseNumberAndName && (
                        <>
                            {values.courseNumberAndName}
                            <br />
                        </>
                    )}
                    {values.instructor && (
                        <>
                            {values.instructor}
                            <br />
                        </>
                    )}
                    {values.date && (
                        <>
                            {moment(values.date).format('MMMM DD, YYYY')}
                            <br />
                        </>
                    )}
                </p>
            </div>
            <div
                className="page-break mceNonEditable"
                style={{ overflow: 'hidden', height: 0, pageBreakAfter: 'always' }}
            />
        </>
    );
};

export default TitlePage;
