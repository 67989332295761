import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import Cite from 'citation-js';
import { parse, HTMLElement, Node } from 'node-html-parser';
import store from 'store';

import { GlobalContext } from '../context/GlobalContextProvider';
import { generateReferenceString } from '../helpers/generateReference';
import { apiRequester } from '../utility';

export const useReferences = (): {
    references: AutoAPA.Reference[];
    activeReference: AutoAPA.Reference | null;
    addReference: (reference: AutoAPA.Reference) => void;
    removeReference: (reference: AutoAPA.Reference) => void;
    editReference: (reference: AutoAPA.Reference) => void;
    setActiveReference: (reference: AutoAPA.Reference | null) => void;
    refreshReferencesPage: () => void;
    generateCitationString: (reference: AutoAPA.Reference) => string;
    loading: boolean;
    convertBibTexToReference: (bibTex: string) => string;
    convertBibTexToCitation: (bibTex: string) => string;
} => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const localReferences = localStorage.getItem('references');
        localReferences && context.setReferences(JSON.parse(localReferences) as AutoAPA.Reference[]);
    }, []);

    const generateCitationString = (reference: AutoAPA.Reference) => {
        const { contributors, publicationYear, originalPublicationYear } = reference;

        let contributorString = ``;
        if (contributors && contributors.length) {
            const numberOfContributors = contributors.length;
            if (numberOfContributors <= 2 && numberOfContributors > 0) {
                contributorString = contributors.map(contributor => contributor.last).join(' & ');
            } else {
                contributorString = `${contributors[0].last} et al.`;
            }
        }

        const yearString =
            originalPublicationYear && publicationYear
                ? `, ${originalPublicationYear}/${publicationYear}`
                : publicationYear
                ? `, ${publicationYear}`
                : ``;

        const citationString = `(${contributorString}${yearString})`;
        const citationSpan = `<span class="referenceCitation mceNonEditable" id="reference-id-${reference._id}">${citationString}</span>`;
        return citationSpan;
    };

    const refreshReferencesPage = (references?: AutoAPA.Reference[]) => {
        // 1. Refresh all citations
        const html = context.html;
        const root = parse(html);
        const citationNodes = root.querySelectorAll('.referenceCitation');
        citationNodes.map(node => {
            const id = node.getAttribute('id');
            const refId = id?.split('reference-id-')[1];
            const reference = references?.find((r: Papers.Reference) => r._id === refId);
            if (!reference) throw new Error('Reference not found');
            const citationHtml = generateCitationString(reference);
            node.innerHTML = citationHtml;
        });

        // 2. Refresh references page
        let referencePageHtml = ``;
        const referencesForSorting: { reference: string; citation: string }[] = [];
        (references || context.activePaper?.references || []).map(ref => {
            if (typeof ref !== 'string') {
                const refHtml = ref.rawReference || generateReferenceString(ref!);
                const refStripped = refHtml.split('\n')[1];
                const finalRefText = refStripped.replaceAll(/div/gi, 'span');
                referencesForSorting.push({
                    reference: `<p style="text-indent: -36px; padding-left: 36px;">${finalRefText}</p>`,
                    citation: ref.rawCitation!,
                });
            }
        });
        referencesForSorting.sort((ref1, ref2) => (ref1.citation > ref2.citation ? 1 : -1));
        referencesForSorting.map(ref => {
            referencePageHtml += ref.reference;
        });
        const pageElement = root.querySelector(`#referenceList`);
        if (pageElement) pageElement.innerHTML = referencePageHtml;
        context.setHtml(root.innerHTML);
    };

    const addReference = async (reference: AutoAPA.Reference) => {
        const newReference = await apiRequester.createReference(reference);
        context.setActivePaper(paper => {
            const newReferences = [...(paper?.references || []), newReference].filter(
                (value, index, self) => index === self.findIndex(t => t._id === value._id),
            );
            refreshReferencesPage(newReferences);
            const newPaper = { ...paper, rawHtml: context.html, references: newReferences };
            store.set('activePaper', newPaper);
            return newPaper;
        });
    };

    const removeReference = (reference: AutoAPA.Reference) => {
        context.setActivePaper(paper => {
            const newReferences = paper?.references?.filter(r => r._id !== reference._id);
            const updatedPaper = {
                ...{ ...paper, rawHtml: context.html.replaceAll(reference.rawCitation!, '') },
                references: newReferences,
            };
            refreshReferencesPage(newReferences);
            store.set('activePaper', updatedPaper);

            return updatedPaper;
        });
    };

    const editReference = (reference: AutoAPA.Reference) => {
        context.setReferences(references => {
            const editedReferences = references.map(r => (r._id === reference._id ? reference : r));
            localStorage.setItem('references', JSON.stringify(references));
            refreshReferencesPage(editedReferences);
            return editedReferences;
        });
    };

    const convertBibTexToReference = (bibTex: string) => {
        setLoading(true);
        const cite = new Cite(bibTex);
        const bibliography = cite.format('bibliography', {
            format: 'html',
            lang: 'en-US',
            template: 'apa',
        });
        setLoading(false);
        return bibliography;
    };

    const convertBibTexToCitation = (bibTex: string) => {
        setLoading(true);
        const cite = new Cite(bibTex);
        const citation = cite.format('citation', {
            format: 'html',
            lang: 'en-US',
            template: 'apa',
        });
        setLoading(false);
        return citation;
    };

    return {
        references: context.activePaper?.references || [],
        activeReference: context.activeReference,
        setActiveReference: context.setActiveReference,
        addReference,
        removeReference,
        editReference,
        refreshReferencesPage,
        generateCitationString,
        loading,
        convertBibTexToReference,
        convertBibTexToCitation,
    };
};

export default useReferences;
